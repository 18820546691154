import React, {useMemo, useState} from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Stack } from "@mui/material";


export const GroupingTable = ({columnas, data, redirect, sort}) => {

  const columns = useMemo(()=> columnas, []);
  const [groupedColumnMode, setGroupedColumnMode] = useState('reorder');

  const table = useMaterialReactTable({
    columns,
    data,
    enableGrouping: true,
    groupedColumnMode,
    initialState: {
      expanded: true,
      grouping: [],
      sorting : [sort]
    },
    muiTableContainerProps: { 
      sx: { maxHeight: '100%' } 
    },
    muiTableHeadCellProps: {
      sx: { 
        backgroundColor: '#575E3B', 
        color: 'white', 
        svg: {color: '#ffffff !important'},
        '& .MuiTableSortLabel-root': {
          svg: { color: 'white !important' }, // Color inicial de las flechas
        },
        '& .MuiTableSortLabel-root.Mui-active': {
          svg: { color: 'white !important' }, // Color de las flechas cuando están activas
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white !important', // Asegura que todos los íconos sean blancos
        }, 
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        redirect(row?.original)
      },
      sx: { cursor: 'pointer', },
    }),
    muiToolbarAlertBannerProps: {
      sx: {
        backgroundColor: 'white', // Change background color of grouped alert banner
        color: '#575E3B', 
        fontWeight: 400,
      },
    },
    muiTableFilterTextFieldProps: {
      sx: {
        color: 'white !important',
        '& .MuiInputBase-input': {
          color: 'white !important', // Forzar el color del texto
        },
        '& .MuiInputBase-input::placeholder': {
          color: 'white !important', // Forzar el color del placeholder
        },
      },
    },
  });

  return (
    <Stack gap="1rem">
      <MaterialReactTable table={table} />
    </Stack>
  );

}