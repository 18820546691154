import { useEffect, useState } from 'react';

const InactivityTimer = ({ maxInactiveTime = 600000, onInactive }) => {
  
  const [inactiveTime, setInactiveTime] = useState(0);

  useEffect(() => {
    const handleActivity = () => {
      setInactiveTime(0); // Reinicia el contador de inactividad
    };

    const incrementInactiveTime = () => {
      setInactiveTime((prevTime) => {
        const newTime = prevTime + 1000;
        if (newTime >= maxInactiveTime) {
          if (onInactive) onInactive(); // Ejecuta la acción de inactividad
          return 0; // Reinicia el tiempo si se desea continuar el monitoreo
        }
        return newTime;
      });
    };

    // Agrega eventos de actividad
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Inicia el temporizador
    const timer = setInterval(incrementInactiveTime, 1000);

    return () => {
      // Limpia el temporizador y eventos al desmontar
      clearInterval(timer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, [maxInactiveTime, onInactive]);

  return null; // Este componente no necesita renderizar nada
};

export default InactivityTimer;
