import React, { useContext } from "react";
import { ThemeContext } from "@emotion/react";
import { Grid, InputAdornment, Table, TableContainer, TextField } from "@mui/material";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from "react-virtuoso";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { GroupingTable } from "../../../../components/Table/grouping_table";

const TablaGeneral = ({sample, filtro, handleInputChange, onPressEnter}) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    const sort = {}

    const columns = [
			{
				accessorKey: 'alma_nombre',
				header: 'Nombre',
			}, 
			{
				accessorFn: (row) => tipos_almacen.find(alm => alm.id === row.alma_tipo)?.label  || '',
				id: 'tipo',
				header: 'Tipo',
				sortingFn: 'text',
				filterFn: 'includesString',
			},
			{
				accessorKey: 'alma_estatus',
				header: 'Estatus',
				Cell: ({ cell }) => (cell.getValue() === 1 ? 'Activo' : 'Inactivo'),
			},
    ]
    const tipos_almacen = [
        {
            id: 1,
            label: "Centro de trabajo"
        },
        {
            id: 2,
            label: "Bodega"
        },
        {
            id: 3,
            label: "Planta"
        },
        {
            id: 4,
            label: "Almacén"
        },
    ]

    const rows = sample;

    const redirect = (row) => {
      if(row){
        navigate(`/inventarios/almacenes/${row?.alma_id}`);
      }
    }

    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                <TextField
                id="search_wharehouse"
                label="Ingrese nombre del almacen"
                name="search_wharehouse"
                size="small"
                value={filtro}
                onChange={handleInputChange}
                onKeyDown={onPressEnter}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search sx={{fontSize:20}}/>
                    </InputAdornment>
                    ),
                }}
                sx={{ color: theme.palette.primary.input }}
                />
            </Grid>
          </Grid>
          <GroupingTable  
          key='tabla_almacen' 
          columnas={columns} 
          data={rows} 
          redirect={redirect}
          sort={sort}
          />
        </Paper>
    )
}

export default TablaGeneral;