import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
    Box, 
    Button, 
    Grid, 
    Step, 
    StepConnector, 
    StepContent, 
    StepLabel, 
    Stepper, 
    Typography, 
    stepConnectorClasses,
    IconButton, 
    FormControl,
    TextField
} from "@mui/material";

import FormOptions from "./form_options";
import FormSecundary from "./form_secundary";
import { Add, Delete, Edit, ExpandMore, Save } from "@mui/icons-material";
import { UpdateRutaParte } from "../../../../../hooks/updateRutaParte";
import { BorrarRutaParte } from "../../../../../hooks/delRutaParte";
import { GetRutaParte } from "../../../../../hooks/getRutasParte";
import swal from 'sweetalert2'
import { useLoading } from "../../../../../components/Loading/useLoading";
import { GetCertificadosList } from "../../../../../hooks/getListCertificados";
import { GetAllFormatos } from "../../../../../hooks/getAllFormatos";


const RutasProducto = ({openModal, id_parte, isOpen}) => {

    const {setAppLoading} = useLoading();
    const {getRutaParteMutation} = GetRutaParte();
    const {updateRutaParteMutation} = UpdateRutaParte();
    const {borrarRutaParteMutation} = BorrarRutaParte();
    const {getCertificadosListMutation} = GetCertificadosList();
	const {getAllFormatosMutation} = GetAllFormatos();
    const [certificados, setCertificados] = useState([]);
    const [formatos, setFormatos] = useState([]);
    const [editName, setEditName] = useState(false);
    const [nameRuta, setNameRuta] = useState('');
    const [selected, setSelected] = useState(
        {
            code: false,
            tag:false,
            continue: false,
            certificate: false
        }
    )
    const [openRuta, setOpenRuta] = useState(null);

    //DATA

    const [steps, setSteps] = useState([])

    const get_rutas = () => {
        setAppLoading(true);
        setSteps([]);
        setSelected([]);
        getRutaParteMutation(id_parte,{
            onSuccess:(data) => {
                let extras = data.map(ruta=>{
                        return {
                                code: ruta?.rupr_codigosustitutoparte,
                                tag: ruta?.foet_id !== null,
                                continue: ruta?.rupr_continuo,
                                certificate: ruta?.rupr_revisionentradasalida,
                                certificate_reporteoid: ruta?.cues_reporteoid !== null,
                                tag_reporteoid: ruta?.foet_reporteoid !== null
                        }
                })
                setSelected(extras)
                setSteps(data)
                setAppLoading(false);
            },
            onError: (error) => {
                console.log(error)
                setAppLoading(false);
            }
        })
    }
    const get_certificados = () => {
        setAppLoading(true)
        getCertificadosListMutation(id_parte, {
            onSuccess: (data) => {
                setCertificados(data)
                setAppLoading(false)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }
    const get_formatos = () => {
      setAppLoading(true);
      getAllFormatosMutation('?active=true',{
          onSuccess: (data) => {
            setFormatos(data);
            setAppLoading(false);
          },
          onError: (error) => {
            console.log(error);
            setAppLoading(false);
          }
      })
    }
    const updateRuta = (event, id, name, index) => {
        setAppLoading(true);
        let itemSelect = selected;
        let data;
        if(name === 'nombre'){
          data = {
            rupr_nombre: nameRuta
          }
        }else{
          itemSelect[index][name] =  !itemSelect[index][name]
          data = {
              [event.target.name]: event.target.checked
          }
        }
        
        if(event.target.name === 'rupr_codigosustitutoparte' && !event.target.checked) data = {...data, rupr_codigocambio: '0-0-0'}
        if(event.target.name === 'rupr_revisionentradasalida' && !event.target.checked) data = {...data, cues_id: null}
        if(event.target.name === 'certificate_reporteoid' && event.target.checked) data = {cues_reporteoid: certificados.length > 0 ? certificados[0]?.id : null}
        if(event.target.name === 'certificate_reporteoid' && !event.target.checked) data = {cues_reporteoid: null}
        if(event.target.name === 'tag' && event.target.checked) data = {foet_id: 1}
        if(event.target.name === 'tag' && !event.target.checked) data = {foet_id: null}
        if(event.target.name === 'tag_reporte' && event.target.checked) data = {foet_reporteoid: 1}
        if(event.target.name === 'tag_reporte' && !event.target.checked) data = {foet_reporteoid: null}
        setSelected(itemSelect);
        updateRutaParteMutation({id: id, data: data},{
            onSuccess: (data) => {
                get_rutas();
                setEditName(false);
                setNameRuta(null);
            },
            onError: (error) => {
                console.log(error)
                setAppLoading(false);
                setEditName(false);
                setNameRuta(null);
            }
        })
    }
    const updateSubForms = (id, name, value) => {
        setAppLoading(true)
        let data = {
            [name]: value
        }
        updateRutaParteMutation({id: id, data: data},{
            onSuccess: (data) => {
                get_rutas();
                setAppLoading(false)
            },
            onError: (error) => {
                console.log(error);
                setAppLoading(false);
            }
        })
    }
    const deleteRuta = (id) => {
        swal.fire({
            title: "¿Esta seguro de elimanar esta ruta?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
        }).then((result) => {
            if (result.isConfirmed) {
                setAppLoading(true)
                borrarRutaParteMutation(id, {
                    onSuccess: (data) => {
                        setAppLoading(false)
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "La ruta se elimino correctamente",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                get_rutas() 
                            }, 1600);
                        });
                    },
                    onError: (error) => {
                        console.log(error)
                        setAppLoading(false)
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "La ruta no se pudo elimnar",
                            text: "Intente nuevamente",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                get_rutas();
                            }, 1600);
                        });
                    }
                })
            }
        });
    }
    const updateCodigos = (index, value) => {
        setAppLoading(true)
        let rutas_update = steps.slice(index);
        for (let ruta of rutas_update){
            updateRutaParteMutation({id: ruta?.rupr_id, data: {rupr_codigosustitutoparte: true, rupr_codigocambio: value}},{
                onSuccess: (data) => {
                    get_rutas();
                    setAppLoading(false)
                },
                onError: (error) => {
                    console.log(error);
                    setAppLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        get_rutas();
        get_certificados();
        get_formatos();
    }, []);

    useEffect(() => {
        get_rutas();
        get_certificados();
        get_formatos();
    }, [isOpen]);

    // Lógica que depende de `formatos`
    useEffect(() => {
        if(openRuta){
            setAppLoading(true);
            if (formatos.length > 0 && certificados.length > 0) {
                setAppLoading(false); // Desactiva el loading después de realizar la acción
            }else{
                setAppLoading(true)
            }
        }
    }, [formatos, openRuta, certificados]);


    //STEPPER

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.vertical}`]: {
            // top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.vertical}`]: {
                borderColor: '#f0f1f1',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.vertical}`]: {
                borderColor: '#f0f1f1',
            },
        },
        [`& .${stepConnectorClasses.vertical}`]: {
            borderColor: '#f0f1f1',
            // borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.main,
        display: 'flex',
        paddingLeft:5,
        alignItems: 'flex-start',
        ...(ownerState.active && {
            
        }),
        '& .QontoStepIcon-completedIcon': {
            zIndex: 1,
            // fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            color:'#f0f1f1',
            border: '3px solid #575E3B',
        },
    }));

    function QontoStepIcon(props) {
        const { active, className } = props;
    
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                <div className="QontoStepIcon-circle" />
            </QontoStepIconRoot>
        );
    }

    const toggleCollapser = (id) => {
        if(id === openRuta){
            setOpenRuta(null)
        }else{
            setOpenRuta(id);
        }
    };


    return(
        <Box sx={{width:'100%'}} key='box_rutas'>
            <Stepper  orientation="vertical" connector={<QontoConnector />}>
                {steps?.map((step, index) => (
                    <Step key={step.rupr_id} active>
                        <StepLabel
                            StepIconComponent={QontoStepIcon}
                        >
                            {null}
                        </StepLabel>
                        <StepContent >
                            <>
                                <div className="collapser" key={`acordeon_${step?.rupr_id}`}>
                                    <div className="collapser-label" onClick={()=>toggleCollapser(step?.rupr_id)}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <ExpandMore className={openRuta === step?.rupr_id ? 'open' : ''}/>
                                            <Typography sx={{fontWeight:500, color: 'black'}}>
                                                {`${step?.almacenes?.alma_nombre} ${step?.rupr_nombre && openRuta !== step?.rupr_id ? `(${step?.rupr_nombre})`:''}`}
                                            </Typography>
                                        </div>
                                        <span className="icon">
                                            <IconButton 
                                                aria-label="delete" 
                                                sx={{right:15, position:'relative', padding:0}}
                                                onClick={()=>deleteRuta(step?.rupr_id)}>
                                                        <Delete />
                                            </IconButton>
                                        </span>
                                    </div>
                                    <div className={openRuta === step?.rupr_id ? 'collapser-content open' : 'collapser-content'}
                                    >
                                        <Grid container spacing={0} sx={{mb:2}}>
                                            <Grid item xs={12} sx={{display:'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems:'center', lineHeight:1, pl:1}}>
                                                <span>Nombre de la Ruta:</span>
                                                {
                                                    editName ? (
                                                        <>
                                                            <TextField
                                                            id="rupr_nombre"
                                                            size="small"
                                                            variant="standard"
                                                            inputProps={{ maxLength: 150 }}
                                                            sx={{ml:1.5}}
                                                            value={nameRuta}
                                                            onChange={(e)=>setNameRuta(e.target.value)}
                                                            autoFocus
                                                            />
                                                            <IconButton aria-label="delete" color="primary" onClick={(e)=> updateRuta(e, step?.rupr_id, 'nombre', index)}>
                                                                <Save sx={{fontSize:20}}/>
                                                            </IconButton>
                                                        </>
                                                    ): (
                                                        <>
                                                            <span style={{fontWeight:600, marginLeft:5}}>{step?.rupr_nombre}</span>
                                                            <IconButton 
                                                            aria-label="delete" 
                                                            color="primary" 
                                                            onClick={()=>{
                                                                setNameRuta(step?.rupr_nombre)
                                                                setEditName(true);
                                                            }}>
                                                                <Edit sx={{fontSize:15}}/>
                                                            </IconButton>
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                        <FormControl component="fieldset" sx={{width:'100%'}}>
                                            <Grid container spacing={0}>
                                                <FormOptions 
                                                    key={'principal_options'} 
                                                    step={step} 
                                                    updateRuta={updateRuta} 
                                                    index={index} 
                                                    length_steps={steps.length}
                                                    />
                                            </Grid>
                                        </FormControl>
                                        <FormSecundary  
                                            key={`secundary_options_${step?.rupr_id}`} 
                                            selected={selected[index]} 
                                            step={step} 
                                            updateRuta={updateSubForms} 
                                            index={index} 
                                            updateCodigo={updateCodigos}
                                            formatos={formatos}
                                        />
                                    </div>
                                </div>
                            </>
                        </StepContent>
                    </Step>
                ))}
                <Step key={'btn_add'} active={true}>
                        <StepLabel
                        StepIconComponent={QontoStepIcon}
                        >
                            {null}
                        </StepLabel>
                        <StepContent >
                            <Button variant="contained" disableElevation onClick={()=> openModal(true)}>
                                <Add/> Agregar nuevo
                            </Button>
                        </StepContent>

                </Step>
            </Stepper>
        </Box>   
    )
    
}

export default RutasProducto;