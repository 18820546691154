import { Button, Grid, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { GetAllFormatos } from "../../../hooks/getAllFormatos";
import { TablaFormatos } from "./componentes/TablaFormatos";
import { useNavigate } from "react-router-dom";

export const EtiquetasIndex = () => {
  const { getAllFormatosMutation } = GetAllFormatos();
  const [sample, setSample] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const get_data_tabla = () => {
    getAllFormatosMutation(
      "",
      {
        onSuccess: (data) => {
          setSample(data);
        },
        onError: (error) => {
          alert("Error al cargar los datos")
        },
      },
    );
  };
  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: ''})
    )
    get_data_tabla();
  }, []);
  return (
    <>
      <Grid container spacing={0} sx={{ height: 100 }}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={2}
          xl={2}
          sx={{ padding: "10px 30px", height: "100%" }}
          className="center_item"
        >
          <Button variant="contained" className="btn_system" onClick={()=> navigate('/inventarios/etiquetas/nuevo')}>
            Crear
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sx={{ padding: "10px 20px", height: "100%" }}
          className="center_item"
        >
          <TablaFormatos key={"table_gen"} rows={sample} />
        </Grid>
      </Grid>
    </>
  );
};
