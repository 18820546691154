import { Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GroupingTable } from "../../../../components/Table/grouping_table";


export const TablaGeneral = ({sample}) => {

  const sort = { id: 'roleId', desc: false}
  const columns = [
    {
      accessorKey: 'roleId',
      header: 'Identificador del Rol',
    },
    {
      accessorKey: 'roleName',
      header: 'Nombre del Rol',
    },
  ]

  const navigate = useNavigate();

  const rows = sample;

  const redirect = (row) => {
    if(row){
      navigate(`/administracion/roles/${row?.roleId}/${row?.roleName}`);
    }
  }

  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
      <GroupingTable  
      key='tabla_roles' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  )
}