import { Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GroupingTable } from "../../../../components/Table/grouping_table";


export const TablaGeneral = ({sample}) => {

	const sort = { id: 'givenName', desc: false}
  const columns = [
    {
      accessorKey: 'givenName',
      header: 'Nombres',
    },
    {
      accessorKey: 'familyName',
      header: 'Apellidos',
    },
    {
      accessorKey: 'username',
      header: 'Usuario',
    },
  ]

  const navigate = useNavigate();

  const rows = sample;

  const redirect = (row) => {
		if(row){
			navigate(`/administracion/usuarios/${row?.username}`);
		}
  }

  return(
	<Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
		<GroupingTable  
		key='tabla_usuarios' 
		columnas={columns} 
		data={rows} 
		redirect={redirect}
		sort={sort}
		/>
	</Paper>
)
}