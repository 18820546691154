import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";

import { GetFormato } from "../../../hooks/getFormato";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { EditFormatoForm } from "./componentes/EditFormatoForm";
import { TablaItems } from "./componentes/TablaItems";
import { PreviewFormato } from "./componentes/PreviewFormato";

export const DataContext = createContext();

export const EtiquetasEditarFormato = () => {
  const dispatch = useDispatch();
  const { getFormato } = GetFormato();
  const [formatoData, setFormatoData] = useState();
  const { id } = useParams();
  const getFormatoData = (id) => {
    getFormato(id, {
      onSuccess: (data) => {
        console.log(data)
        setFormatoData(data);
      },
      onError: (error) => {
        alert("Error al cargar los datos");
      },
    });
  };
  useEffect(() => {
    dispatch(HEADER_DATA_UPDATE({ nombreData: "Editar Formato" }));
    getFormatoData(id);
  }, []);

  return (
    <>
        {formatoData && <EditFormatoForm setData={setFormatoData} defaultData={formatoData} />}
        {!formatoData && (
          <div
            style={{
              padding: 40,
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <span style={{ paddingRight: "20px", fontSize: 40 }}>
              Cargando...
            </span>
            <LoadingOutlined style={{ fontSize: 60 }} spin />
          </div>
        )}
        <Grid container spacing={2}>
          {formatoData && <TablaItems data={formatoData} setData={setFormatoData}/>}
          {formatoData && <PreviewFormato data={formatoData} setData={setFormatoData}/>}
        </Grid>
    </>
  );
};
