import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper
} from "@mui/material";
import { GroupingTable } from "../../../../components/Table/grouping_table";

export const TablaFormatos = ({ rows }) => {
  const navigate = useNavigate();
  const sort = {}
  const columns = [
    {
      accessorKey: 'foet_nombre',
      header: 'Nombre',
    },
    {
      accessorKey: 'foet_descripcion',
      header: 'Descripción',
    }
  ]
  const redirect = (row) => {
    if(row){
      navigate("/inventarios/etiquetas/" + row.foet_id)
    }
  }

  return (
    <Paper style={{ height: '75vh', width: "100%" }} sx={{ boxShadow: "none" }}>
      <GroupingTable  
      key='tabla_etiquetas' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  );
};
