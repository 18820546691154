import { Grid } from "@mui/material"
import { RequestFormTrazabilidad } from "./components/requestForm"
import { GetMapaTrazabilidad } from "../../../hooks/getMapsTrazabilidad";
import { MapaTrazabilidad } from "./components/mapa";
import { useEdgesState, useNodesState } from "@xyflow/react";
import { useLoading } from "../../../components/Loading/useLoading";
import { useState } from "react";

const multlineNodeStyle = {
  color: "#000",
  border: "1px solid #000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "10px 20px",
  background: "#fff",
  borderRadius: 5,
  fontSize: 12,
  transition: "all 0.3s",
  width: "450px"
};
const mainNodeStyle = {
  color: "#fff",
  border: "1px solid #575E3B",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "10px 20px",
  background: "#575E3B",
  borderRadius: 5,
  fontSize: 11,
  transition: "all 0.3s",
};


export const TrazabilidadIndex = () => {

  const {setAppLoading} = useLoading();
  const {getMapaTrazabilidadMutation} = GetMapaTrazabilidad();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [dataExport, setDataExport] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);


  const InfoNodo = ({data, main = false}) => {


    let tags_unicas;
    if(data?.etiquetas){
      tags_unicas = [...new Set(data?.etiquetas)];
    } 

    return(
      <div  key={`info_${data?.id}`} style={{display: 'flex', flexDirection: 'column'}}>
        {
          data?.etiqueta && (
            <span>{data?.etiqueta}</span>
          )
        }
        <span style={{color: data?.etiquetas || !main ? '#575E3B': 'auto' }}>Almacen(s):</span>
        <span>{data?.almacen}</span>
        <span style={{color: data?.etiquetas || !main ? '#575E3B': 'auto' }}>Cantidad:</span>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <span>{data?.cantidad}</span>
          { data?.total && 
            <span>/{data?.total}</span>
          }
        </div>
        {
          data?.folios && (
            <>
              <span style={{color: !main ? '#575E3B': 'auto' }}>Seriales:</span>
              <div style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
                {
                  data?.folios?.join(' / ')
                }
              </div>
            </>
          )
        }
        {
          data?.etiquetas && (
            <>
              <span style={{color: '#575E3B'}}>Etiquetas:</span>
              <div style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
                {
                  tags_unicas?.join(' / ')
                }
              </div>
            </>
          )
        }
      </div>
    )
  }
  const EntryNodo = ({data}) => {
    return(
      <div key={`info_${data?.id}`} style={{display: 'flex', flexDirection: 'column'}}>
        <span style={{color: '#575E3B'}}>{data?.intermedio ? 'Movimiento intermedio' : 'Documento Folio'}</span>
        {
          !data?.intermedio &&
          <span>{data?.documento}</span>
        }
        {
          data?.cantidad !== null && !data?.total && (
            <>
              <span style={{color: '#575E3B'}}>Cantidad:</span>
              <span>{data?.cantidad}</span>
            </>
          )
        }
        {
          data?.cantidad !== null && data?.total && (
            <>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <span>{data?.cantidad}</span>
              { data?.total && 
                <span>/{data?.total}</span>
              }
            </div>
            </>
          )
        }
      </div>
    )
  }

  const replaceIdsMovs = (nodes_rep, edge_rep) => {
    // Crear un mapa para rastrear los nuevos valores de id
    const idMap = new Map();
        
    // Asignar ids consecutivos al primer array
    nodes_rep.forEach((obj, index) => {
        const nuevoId = index + 1; // Numeración consecutiva empezando en 1
        idMap.set(obj.id, nuevoId); // Guardar la relación viejoId -> nuevoId
        obj.id = nuevoId; // Actualizar el id del objeto en el primer array
    });

    // Actualizar el segundo array usando el mapa de id
    edge_rep.forEach(obj => {
        if (idMap.has(obj.source)) {
            obj.source = idMap.get(obj.source);
        }
        if (idMap.has(obj.target)) {
            obj.target = idMap.get(obj.target);
        }
    });

    return { nodes_rep, edge_rep };
  }

  const getDataExportTag = (nodes, edges) => {
    let nodos_sorto = [...nodes].sort((a, b) => b.level - a.level);
    let nodes_data = structuredClone(nodos_sorto);
    let edges_data = structuredClone(edges);
    let {nodes_rep, edge_rep} = replaceIdsMovs(nodes_data, edges_data);

    let nodos_excel = nodes_rep?.map((node) => {
      let edge = edge_rep.find(ed => ed?.source === node?.id);
      let edge_padres_row = [...edge_rep.filter(ed => ed?.target === node?.id)];
      let edges_padres = edge_padres_row?.map(ed => ed.source);
      let tipo =  node?.documento && node?.level === 0 ? 'Salida' :
                  node?.documento ? 'Entrada' : 'Movimiento'
      let responsables = node?.responsables.map(item => item.split(" - ")[0]);
      let fechas = node?.responsables.map(item => item.split(" - ")[1]);
      let ordenes = [...new Set(node?.orden)]
      let folios = node?.serial_cantidad?.map(item => { return `${item?.moin_serial} (${item?.cantidad})` })

      let nodo = {
        tipo: tipo,
        documento_folio: node?.documento ? node?.documento : '',
        orden_movimiento: node?.id,
        almacen: node?.almacen,
        seriales: folios?.join(' | '),
        etiqueta: node?.etiqueta,
        cantidad: node?.cantidad,
        responsables: responsables.join(' | '),
        fechas: fechas.join(' | '),
        ordenes: ordenes?.join(' | '),
        id_movimiento_padre: edges_padres ? edges_padres?.join(' | ') : '',
        id_movimiento_hijo: edge ? `${edge?.target}` : ''
      }

      return nodo;
    })

    return nodos_excel
  }
  const getDataExportSerial = (nodes, edges) => {
    let nodes_data = structuredClone(nodes);
    let edges_data = structuredClone(edges);
    let {nodes_rep, edge_rep} = replaceIdsMovs(nodes_data, edges_data);

    let nodos_excel = nodes_rep?.map((node) => {
      let edge = edge_rep.find(ed => ed?.source === node?.id);
      let edge_padres_row = [...edge_rep.filter(ed => ed?.target === node?.id)];
      let edge_hijos_row = [...edge_rep.filter(ed => ed?.source === node?.id)];
      let edges_padres = edge_padres_row?.map(ed => ed.source);
      let edges_hijos = edge_hijos_row?.map(ed => ed.target);
      // let etiquetas = [...new Set(node?.etiquetas)]
      let responsables = node?.responsables.map(item => item.split(" - ")[0]);
      let fechas = node?.responsables.map(item => item.split(" - ")[1]);
      let ordenes = [...new Set(node?.orden)]
      let etiquetas = node?.serial_cantidad?.map(item => { return `${item?.moin_serial} (${item?.cantidad})` })
      let tipo =  node?.documento && node?.level === 0 ? 'Entrada' :
                  node?.documento ? 'Salida' : 'Movimiento'
      
      let nodo = {
        tipo: tipo,
        documento_folio: node?.documento ? node?.documento : '',
        id_movimiento: node?.id,
        almacen: node?.almacen,
        etiqueta: etiquetas?.join(' | '),
        cantidad: node?.cantidad,
        responsables: responsables.join(' | '),
        fechas: fechas.join(' | '),
        ordenes: ordenes?.join(' | '),
        id_movimiento_padre: edges_padres ? edges_padres?.join(' | ') : '',
        id_movimiento_hijo: edge ? edges_hijos?.join(' | ') : ''
      }

      return nodo;
    })

    return nodos_excel
  }


  const search = (body) => {
    setAppLoading(true);
    setSelectedNode(null);
    getMapaTrazabilidadMutation(body,{
      onSuccess: (data) => {
        if(data?.data){
          let nodes = data.data.nodes;
          let edges = data.data.edges;
  
          let x = 0;
          let y = 300;
          
          let nodos = nodes?.map((node, index) => {

            let nodo_filtrado = node;

            nodo_filtrado.orden =  [...new Set(nodo_filtrado.orden)];
  
            let nodo = {
              id: node?.id,
              position: { x: x, y: y },
              data: { 
                label: node?.documento ?
                <EntryNodo key={`${node.id}_info`} data={nodo_filtrado}/> :
                <InfoNodo key={`${node.id}_info`} data={nodo_filtrado} main={node.id === body.codigo}/>
              },
              style: node.id === body.codigo ? mainNodeStyle : multlineNodeStyle ,
            }
            if(nodes[index+1]?.level === node?.level){
              x+=500;
            }else{
              x=0;
              if(body.tipo === 1){
                nodes[index+1]?.level - node[0]?.level ? y+=200 : y-=200
              }else{
                if(nodes.some(obj => obj?.level > nodes[index+1]?.level)){
                  y+=350
                }else{
                  if(nodes.some(obj => obj?.level === nodes[index]?.level-1 && obj?.etiquetas?.length > 30) || !node?.etiquetas){
                    y+=450
                  }else{
                    y+=1000
                  }
                }
              }
            }
            return nodo;
          })
  
          if(data.data.tipo === 'tag'){
            let data_export = getDataExportTag(nodes, edges);
            setDataExport(data_export)
          }else{
            let data_export = getDataExportSerial(nodes, edges);
            setDataExport(data_export)
          }

          setEdges(edges);
          setNodes(nodos);
        }
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      }
    })
  }

  return(
    <Grid key='container_maps' container spacing={0} sx={{height:'87vh'}}>
      <Grid item xs={12} sx={{padding:'10px 20px', flexDirection:'column', justifyContent: 'flex-start'}} className="center_item">
        <RequestFormTrazabilidad  key='form_trazabilidad' search={search} sampleExcel={dataExport}/>
        <MapaTrazabilidad 
        key='mapas' 
        initialEdges={edges} 
        initialNodes={nodes} 
        onEdgesChange={onEdgesChange} 
        onNodesChange={onNodesChange} 
        setEdges={setEdges} 
        setNodes={setNodes}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}/>
      </Grid>
    </Grid>
  )
}