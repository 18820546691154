import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Divider, Grid, Typography } from "@mui/material";
import { FiltrosExitencias } from "./components/filtros";
import { ReporteGeneral, ReporteIndividual } from "../../../hooks/reportes";
import { TablaReportesAlmacen } from "./components/tabla_almacen";
import { ThemeContext } from "@emotion/react";
import { useLoading } from "../../../components/Loading/useLoading";
import AyudasVisuales from "../../inventario/panel_control/components/ayudasVisuales";
import { exportToExcel } from "react-json-to-excel";


export const ExistenciasIndex = () => {

  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const {setAppLoading} = useLoading();
  const {reporteGeneralMutation} = ReporteGeneral();
  const [sample, setSample] = useState([]);
  const [where, setWhere] = useState(null);
  const [openAyudas, setOpenAyudas] = useState(false);
  const [ayudasVisuales, setAyudasVisuales] = useState([]);
  const {reporteIndividualMutation} = ReporteIndividual();

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: null})
    )
  }, []);

  const search = (body) => {
    setWhere(body)
    setAppLoading(true);
    reporteGeneralMutation(body, {
      onSuccess: (data) =>{
        if(data.success){
          setSample(data?.data);
        }
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error)
        setAppLoading(false);
      }
    })
  }

  const get_movimientos = (body) => {
    return new Promise((resolve, reject) => {
      reporteIndividualMutation(body,{
        onSuccess: (data) => {
          if(data?.success){
            resolve(data?.data)
          }
        },
        onError: (error) => {
          console.error(error)
          reject(error)
        }
      })
  })
  }

  const searchExport = async () => {
    try {
      let data_export = []
      for(let item of sample){
        for(let data of item?.data){
          let body = {
            almacen: item?.alma_id,
            status: where?.status,
            part_id: data?.part_id,
            fecha_inicio: where?.fecha_inicio,
            fecha_fin: where?.fecha_fin,
            export: true
          }
          let response = await get_movimientos(body);
          data_export.push(response)
        }
      }
      return data_export
    } catch (error) {
      console.error(error)
    }

  }

  const exportExcel = async () => {
    setAppLoading(true)
    let data = await searchExport();
    let data_export = data.flat();
    setAppLoading(false);
    exportToExcel(
      data_export, 
      `galus_existencias`
    )
  }


  return(
    <>
      <Grid container spacing={0} sx={{height:'87vh'}}>
        <Grid item xs={12} sx={{padding:'10px 20px', flexDirection:'column', justifyContent: 'flex-start'}} className="center_item">
          <FiltrosExitencias  key='filtros_existencias' search={search} size={sample.length} searchExport={exportExcel}/>
          <Divider  sx={{width: '100%', mt:1, mb:2}}/>

          {
            sample.length > 0 &&
            <Typography 
              sx={{
                fontWeight: 600, 
                fontSize:22, 
                mb:2,
                color: theme.palette.primary.main
              }}>
              Resultados de Búsqueda
            </Typography>
          }

            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={openAyudas ? 10:12} lg={openAyudas ? 10:12} xl={openAyudas ? 10:12} sx={{p:1, alignItems:'flex-start'}} className="center_item">
                {
                  <TablaReportesAlmacen key='tablas_almacen' sample={sample} where={where} setAyudasVisuales={setAyudasVisuales} setOpenAyudas={setOpenAyudas}/>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={openAyudas ? 2:0} lg={openAyudas ? 2:0} xl={openAyudas ? 2:0} sx={{p:1}}>
                {
                  openAyudas && 
                  <AyudasVisuales
                  key={'ayudas_visuales_gen'}
                  ayudas={ayudasVisuales}
                  setAyudas={setAyudasVisuales}
                  setOpenAyudas={setOpenAyudas}/>
                }
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </>
  )
}